import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    healthHouseDeviceName: localStorage.getItem('healthName') ? localStorage.getItem('healthName') : null
  },
  mutations: {
    set_healthHouseDeviceName(state,data){
      state.healthHouseDeviceName = data
      localStorage.setItem('healthName',data)
    }
  },
  actions: {
    LOGIN({commit},data){
      const {account,password} = data;
      return new Promise(resolve=>{
        console.log(account,password,'account,password')
        commit('set_healthHouseDeviceName',account)
        resolve()
      })
    }
  },
  modules: {
  }
})
